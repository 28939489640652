<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12 mx-auto">
        <div class="card">
          <div v-if="loading" class="card-body">
            <div class="text-center">
              <b-spinner variant="primary"></b-spinner>
            </div>
          </div>
          <div v-else class="card-body">
            <b-alert
              v-if="reqError"
              variant="danger"
              class="my-0"
              show
              v-html="reqError"
            />

            <template v-else>
              <div class="row mb-md-2 text-center">
                <div class="col-sm-12 col-md-auto">
                  <div id="tickets-table_length" class="dataTables_length">
                    <router-link to="/templates/create" class="btn btn-primary"
                      ><i class="mdi mdi-plus-circle mr-1"></i
                      >{{ $t("template.add") }}</router-link
                    >
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-auto ml-auto mt-2 mt-md-0">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                  >
                    <label class="d-inline-flex align-items-center mr-2">
                      <span class="text-nowrap">{{ $t("quantity") }}:</span>
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        class="form-control form-control-sm ml-2"
                      ></b-form-select>
                    </label>
                    <label class="d-inline-flex align-items-center">
                      {{ $t("search") }}:
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="..."
                        class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>

              <template v-if="tableData.length">
                <div class="table-responsive mb-0">
                  <b-table
                    :items="tableData"
                    :fields="tableFields"
                    responsive="sm"
                    :per-page="perPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    class="template-table"
                    striped
                  >
                    <template #head(name)>
                      {{ $t("table.name") }}
                    </template>

                    <template #head(type)>
                      {{ $t("table.category") }}
                    </template>

                    <template #head(metrics)>
                      {{ $t("table.metrics") }}
                    </template>

                    <!-- type/category -->
                    <template #cell(type)="data">
                      {{ typeToStr(data.item) }}
                    </template>

                    <!-- metrics -->
                    <template #cell(metrics)="data">
                      {{ metricsToStr(data.item) }}
                    </template>

                    <!-- action -->
                    <template #cell(action)="data">
                      <div class="button-list text-nowrap">
                        <router-link
                          v-if="userPermissionsIDs.includes(26)"
                          :to="{ path: '/templates/view/' + data.item.id }"
                          class="btn btn-primary btn-xs"
                          :title="$t('btn.view')"
                        >
                          <i class="mdi mdi-eye"></i>
                        </router-link>
                        <router-link
                          v-if="userPermissionsIDs.includes(29)"
                          :to="{ path: '/templates/edit/' + data.item.id }"
                          class="btn btn-success btn-xs"
                          :title="$t('btn.edit')"
                        >
                          <i class="mdi mdi-pencil"></i>
                        </router-link>
                        <b-button
                          v-if="userPermissionsIDs.includes(30)"
                          variant="danger"
                          size="xs"
                          @click="confirmDeleteTemplate(data.item)"
                          :title="$t('btn.delete')"
                        >
                          <i class="mdi mdi-close"></i>
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                </div>

                <div class="row mt-2">
                  <div class="col-6">
                    <div class="my-1">
                      {{ displayedRows }} / {{ totalRows }}
                    </div>
                  </div>
                  <div class="col-6">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </template>
              <div v-else class="text-center">
                <div class="mb-3">{{ $t("template.no-templates") }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import axios from "axios";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/Page-header";
import { mapGetters } from "vuex";

export default {
  metaInfo() {
    return {
      title: this.$appConfig.title + " | " + this.$t("sidebar.templates"),
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "sidebar.templates",
      items: [
        {
          text: this.$appConfig.title,
          to: "/",
        },
        {
          tran: "sidebar.templates",
          active: true,
        },
      ],
      loading: true,
      reqError: false,

      tableData: [],
      totalRows: 30,
      currentPage: 1,
      pageOptions: [10, 25, 50, 100],
      perPage: 25,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,

      toggleDetailsAll: true,
      tableFields: [
        {
          key: "id",
          sortable: true,
          class: "cell-id",
        },
        {
          key: "name",
          sortable: true,
          class: "cell-name",
        },
        {
          key: "type",
          sortable: true,
        },
        {
          key: "metrics",
          class: "cell-metrics",
        },
        {
          key: "action",
          label: "",
          class: "cell-action",
        },
      ],
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters(["userPermissionsIDs"]),
    displayedRows() {
      const items = this.currentPage * this.perPage;
      return items < this.totalRows ? items : this.totalRows;
    },
  },
  methods: {
    async loadData() {
      try {
        this.loadTableData();
      } catch (error) {
        console.log("loadData Error: ", error);
      }
    },
    async loadTableData(silent = false) {
      if (!silent) this.loading = true;
      this.reqError = false;

      try {
        const response = await axios.get(
          this.$urls.URL_TEMPLATES +
            `?page=${this.currentPage}&per_page=${this.perPage}`
        );
        // console.log("response: ", response);
        this.tableData = response.data.data;
        this.totalRows = response.data.total;
      } catch (error) {
        console.log("loadTableData, error: ", error);
        this.reqError = true;
        if (error.response?.data?.message) {
          this.reqError = `<b>[ERROR: ${error.response.status}]</b> ${error.response?.data?.message}`;
        }
      } finally {
        this.loading = false;
      }
    },
    confirmDeleteTemplate(item) {
      this.$bvModal
        .msgBoxConfirm(
          `${this.$t("modal.want-delete-template")}: ${item.name}?`,
          {
            title: this.$t("modal.confirm-delete"),
            size: "md",
            buttonSize: "md",
            okVariant: "danger",
            okTitle: this.$t("btn.yes"),
            cancelTitle: this.$t("btn.no"),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((sure) => {
          if (sure) {
            this.deleteTemplate(item.id);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    deleteTemplate(id) {
      const formData = new FormData();
      formData.append("_method", "DELETE");

      axios
        .post(this.$urls.URL_TEMPLATES + "/" + id, formData)
        .then(() => {
          this.loadTableData();
        })
        .catch(function(error) {
          console.log(error.response);
        });
    },
    typeToStr(item) {
      // console.log("item: ", item);

      // subcategory
      if (item.type === 0) {
        return `${item.templateable?.category?.name} / ${item.templateable?.name}`;
      }

      // Category
      if (item.type === 2) {
        return `${item.templateable?.name}`;
      }
    },
    metricsToStr(item) {
      const metricsNames = item.metrics.map((m) => m.name);
      return metricsNames.length ? metricsNames.join(",\n") : "";
    },
  },
  watch: {
    currentPage() {
      this.loadTableData();
    },
    perPage() {
      this.loadTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
.template-table ::v-deep .cell-metrics {
  white-space: pre-line;
}
</style>
